$primary-color: #198cdc;
$background-color: #fff;

#lap-cookies-banner {
  position: fixed;
  bottom: 0;
  background-color: $background-color;
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 1000;
  left: 0;
  text-align: center;
  border-top: 1px solid $primary-color;
  width: 100%;
  /* hidden by default */
  transition : 180ms transform ease-out;
  transform  : translateY(+100%);
  flex-direction: column;

  &.shown {
    transform  : translateY(0);
    transition : 150 transform ease-in;
  }

  .banner-intro {
    width: 40%;
    position: relative;
    left: 30%;
    text-align: left;

    .lap-close {
      opacity: 0.5;
      display: inline-block;
      width: 14px;
      height: 14px;

      &:hover {
        opacity: 1;
      }

      &:before, &:after {
        position: absolute;
        left: 5px;
        content: ' ';
        height: 15px;
        width: 3px;
        background-color: $primary-color;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .banner-img {
    width: 50px;
    position: absolute;
    padding-top: 5px;
    height: 50px;
  }

  .banner-title {
    font-size: 120%;
    font-weight: bold;
    text-align: left;
    padding-left: 60px;
  }

  .banner-text {
    min-height: 40px;
    padding-left: 60px;
    font-size: 80%;
    text-align: left;
  }

  .banner-buttons {
    padding-top: 25px;
    margin-bottom: 10px;

    .lap-accept,
    .lap-refuse,
    .lap-cookies-more,
    .btn.active {
      border: 1px solid $primary-color;
      font-weight: bold;
      background-color: $primary-color;
      color: $background-color !important;
      box-sizing: border-box;
      transition: background-color 350ms ease-in-out;
    }

    .lap-accept:hover,
    .lap-refuse:hover,
    .lap-cookies-more:hover,
    .btn.selected {
      background: white;
      color: $primary-color !important;
      border: 1px solid currentColor;
      box-sizing: border-box;
      text-decoration: none;
    }
  }

  .close-button {
    position: absolute;
    left: 100%;
    cursor: pointer;
  }

  .btn {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    white-space: nowrap;
    padding: 3px 10px;
    font-size: 9pt;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .banner-checkboxes {
    font-size: 80%;
    margin-top: 1em;
  }

  .checkbox-label {
    margin-left: 0.3em;
    margin-right: 1.5em;
    top: -0.2em;
    position: relative;
  }

  @media(max-width: 500px) {
    font-size: 80%;
    .btn {
      font-size: 80%;
    }
    .banner-intro {
      width: 80%;
      position: relative;
      left: 10%;
      text-align: left;
    }
  }
  @media(max-width: 1000px) {
    .banner-intro {
      width: 80%;
      position: relative;
      left: 10%;
      text-align: left;
    }
  }
}
