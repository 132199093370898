div.services-menu {
    width: 95%;
    margin: 18px auto;
    border-style: solid;
    border-color: transparent;
    border-width: 3px;
    border-radius: 15px;
    text-align: center;

    &:hover {
        box-shadow: 2px 2px 2px;
        background: #1a252f;
        font-weight: bold;
    }

    & a.entry {
        display: block;
        font-size: 23px;
        color: white;
        padding: 19px;
    }

    & a.subtitle {
        color: white;
        display: block;
        padding-bottom: 6px; 
        text-decoration: none;
        font-size: 14px;
        margin-bottom: 10px;
    }
}
    
ul.services-navbar {
    margin:0; 
    padding:0;
    font-size:smaller;

    & li {
        display:inline;

        &.active {
            color:#f47d22;
        }
    }

    & li a {
        text-decoration: none;
    }
}

a.services-button {
    background-color: #4c7aa7;
    text-decoration: none;
    text-align: center;
    color: white;
    display: block;
    border: solid transparent 1px;
    border-radius: 10px;
    padding: 12px;

    &:hover {
        box-shadow: 2px 2px 2px black;
        font-weight: bold;
        color: white;
    }
}

.services-menu-button {
    background-color: #35506b;
    border-radius: 15px;
    min-width: 200px;
    color: white;
    padding: 25px 30px;
    text-decoration: none;
    display: inline-block;
    margin: auto;
    margin-left: 15px;
    margin-bottom: 10px;

    &:hover {
        box-shadow: 2px 2px 2px black;
        font-weight: bold;
        color: white;
    }
}
  
/**
* Services catalog boxes
*/

div.service-box-container {
    display: flex;
    width: 94%;
    border: solid 2px transparent;
    border-radius: 15px;
    margin: 30px auto;
    padding:15px;

    &:nth-child(even) {
        background-color: #4c7aa7;
    }
      
    &:nth-child(odd) {
        background-color: #35506b;
    }
    
    & div.service-logos {
        background-color: white;
        text-align: center;
        margin: 25px 10px;
        width: 28%;
        height: fit-content;
        border-radius: 10px;
        padding: 2%;
    
        &:hover {
            box-shadow: 3px 3px 2px;
        }

        & div.header-underline {
            border-bottom: 2px #f47d21 solid;
        }

        & a.logo-header {
            display: inline-block;
            width: 90%;
            padding: 6px;
            font-size: 19px;
            color: #2c3e50;
            text-decoration: none;
        }

        & .logo-image {
            border: solid 10px transparent;
            width: 90%;
        }
    }


    & .description-right-column {
        text-align: justify;
        color: aliceblue;
        width: 68%;
        margin: 3px;
    }

    & p.description-content {
        color: white;
        margin: 20px 15px 10px 10px;
    }

    & a.read-more {
        text-decoration: none;
        color: #2c3e50;
    }

    & p.read-more-button{
        background-color: aliceblue;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        width: 25%;
        margin: 20px auto 5px;
        border: solid lightgray 1px;
        border-radius: 10px;
        padding: 10px;
        
        &:hover {
            box-shadow: 3px 3px 2px;
        }
    }
}
