// VARIABLES
$COLOR_BACKGROUND_HEADER: #023452;
$COLOR_LINK_ELIXIR: #f47d21;
$COLOR_TEXT: #222;

$FONT_DEFAULT: 'Lato', Verdana, Helvetica, sans-serif;

$HEADER_RESPONSIVENESS_THRESHOLD: 1025px;
$FOOTER_RESPONSIVENESS_THRESHOLD: 600px;

// GENERIC
html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $FONT_DEFAULT;
    font-variant-ligatures: none;
    font-size: 16px;
    margin: 0px;
    overflow-x: hidden;
}

main {
  overflow-x: auto;
}

a {
  color: $COLOR_BACKGROUND_HEADER;
}

a:hover {
  color: $COLOR_LINK_ELIXIR;
}

p {
  color: $COLOR_TEXT;
}
