#site-content {
  h1.title {
    font-size: 37px;
    color: #005472;
    line-height: 1.1;
    font-weight: 300;
    margin: 0 0 12px;
  }

  article.one_column {
    display: block;
  }

  article {
    padding-bottom: 50px;
    min-height: 400px;
    padding-right: 10px;
    margin: 0;
    display: flex;

    hr {
      border-top: solid 1px #f7f7f7;
    }

    div.narrower-article-div {
      .index-flex {
        margin-top: 75px;

        .index-smaller-gray-box {
          margin-right: 1%;
          margin-left: 1%;
          min-width: 200px;
          background: #efefef;
          border: 1px solid #ebebeb;
          padding: 0px !important;
        }
        
        .index-box {
          flex-grow: 1;
          margin-right: unquote("min(5%, 20px)");
          margin-bottom: unquote("min(5%, 20px)");
          border-radius: 0 0 12px 12px;

          padding: 20px;
          width: auto;

          &.index-gray-box {
            background: #efefef;
            border: 1px solid #ebebeb;
          }

          &.index-white-box {
            background: none;
            border: 1px solid #ebebeb;
          }

          img {
            width: 100%;
          }

          .index-box-caption {
            color: #262626;
            font-size: 1rem;
            padding: 12px 5px 12px 12px;

            a {
              text-decoration: none;
              font-weight: bolder;
            }
          }
        }

        .index-photo {
          border: solid 0 black;
          flex-grow: 1;
          margin-right: 2%;

          img {
            width: -webkit-fill-available;
            max-width: 510px;
            min-width: 155px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }

          &.index-box img {
            padding-top: 10px;
            padding-bottom: 10px;
            max-width: 160px;
          }
        }

      }
    }

    img.fit-width {
      display: -webkit-box;
      width: -webkit-fill-available;
      max-width: 600px;
    }

    #right-column {
      padding-left: 40px;
      padding-right: 20px;
      min-width: 240px;
      margin-top: 23px;
    }

    #left-column {
      margin-left: 10px;
      padding-top: 20px;
      padding-right: 40px;
      width: 220px;
      min-width: 220px;

      h2 {
        color: #005472;
        font-size: 25px;
        line-height: 1.2;
        font-weight: 300;
        margin: 0;
        border-bottom: 1px solid #ccc;
      }

      ul {
        width: 200px;
        padding-inline-start: 20px;
        list-style: none;
        margin: 0;

        li {
          padding: 0;
          margin: 0;

          a {
            color: #222;
            line-height: 1.2;
            text-decoration: none;
            display: block;
            border-bottom: 1px solid #efefef;
            padding: 10px 5px 10px 15px;
          }

          a.active {
            font-weight: 800;
            color: $COLOR_LINK_ELIXIR !important;
          }

          a.smaller {
            font-size: smaller;
            margin-left: 25px;
          }

          a:hover {
            color: #060606;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

table {
  margin-top: 10px;
  border: solid 1px #ffdfdf;
  border-collapse: collapse;

  thead tr {
    font-weight: 700;

    th {
      background: $COLOR_BACKGROUND_HEADER;
      padding: 7px 12px;
      color: #f6f6f6;
      border-bottom: 0;
      text-align: left;
    }
  }

  tbody tr {
    padding: 9px 2px;
    border: solid 1px #d6d6d6;

    td {
      padding: 5px 20px 5px 10px;
    }
  }
}

div.post {
  border-left: solid 20px #f4f4f4;
  border-bottom: solid 6px #f4f4f4;
  padding-left: 15px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 35px;
  line-height: 23px;

  .post-title {
    font-size: 14pt;
    text-decoration: none;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }

  .content {
    font-size: 15px;
    font-weight: 500;
  }

  .post-date {
    font-size: smaller;
    font-weight: 200;
    color: #3c3c3c;
    display: block;
    margin-top: 8px;
    padding-top: 2px;
    padding-bottom: 1px;
    border-top: solid 1px #fbf4f4;
  }
}

div header h1 {
  font-size: xx-large;
  font-weight: 100;
  text-transform: uppercase;
}

input.search {
  width: 330px;
  height: 35px;
  font-size: 20px;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  border: solid 3px #f47d21;
}

button.search {
  margin-left: 8px;
  height: 50px;
  font-size: 20px;
}
