#site-header {
  background: $COLOR_BACKGROUND_HEADER;
  height: 93px;
  margin-top: -15px;
  margin-bottom: 37px;

  #site-header-wrapper {
    ul {
      @media only screen and (max-width: $HEADER_RESPONSIVENESS_THRESHOLD) {
        padding-inline-start: 5px;
      }
      @media only screen and (min-width: $HEADER_RESPONSIVENESS_THRESHOLD) {
        padding-inline-start: 265px;
      }
    }

    .elixir-image {
      position: relative;
      top: 26px;
      float: left;
      width: 220px;
      margin-right: 20px;

      img {
        width: 150px;
        padding-left: 10px;
      }
    }

    .hamburger {
      background: none;

      input {
        display: none;
      }
      @media only screen and (max-width: $HEADER_RESPONSIVENESS_THRESHOLD) {
        width: 50px;
        height: 50px;
        border: solid 2px #e6e6e6;
        border-radius: 12px;
        float: right;
        position: relative;
        top: 30px;
        right: 20px;
        z-index: 1;
        background-color: #023452;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEQAACxEBf2RfkQAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS40E0BoxAAAAbNJREFUeF7t1rFOw0AQhOHUUBJK8uAI8Q4g3oUU8BTQm7nIlqz1P0qFc6u9kT7JN01uE8XawzRNpWFZCZaVYFkJlpVgWQmWlWBZCZaVYOlkC80QYelkC80QYelkC80QYelkC80QYelkC80QYelkC80QYelkC80QYelkC80QYemso/NRnuVTvm7sLC9ynK93ic5XYeks0fOdvEtvaXe6n6+5uT/B0lmi5yf5kd7yK6f5mpv7EyydJXpuX0D7sN7SfpRdvoBe/wJv8v9/gRad20uwvXjaC+j7xtod2gv5Yb7eJTpfhaWTLTRDhKWTLTRDhKWTLTRDhKWTLTRDhKWTLTRDhKWzjs6P8irtDUzr6Z5usgp/SG/ZbRU+SY+b4FiFZazC8zU39ydYOuvoPFbh3kMzRFg62UIzRFg62UIzRFg62UIzRFg62UIzRFg66+g8VmHpLWMVlrEKz9fc3J9g6SzR81iFdR6rcO+hGSIsnWyhGSIsnWyhGSIsnWyhGSIsnWyhGSIsnWyhGSIsnWyhGSIsnWyhGSIsK8GyEiwrwbISLCvBshIsK8GyEiwrwbISLOuYDn8dSvgjYpq2oAAAAABJRU5ErkJggg==");
        background-size: contain;

        input {
          opacity: 0;
          display: block;
          width: 50px;
          height: 50px;
          margin: 0;
          z-index: 10;
          cursor: pointer;
          -webkit-touch-callout: none;
        }

        input ~ ul {
          display: none;
          height: 0;
        }

        input:checked ~ ul {
          display: block;
          float: left;
          background-color: #023452;
          width: 150px;
          position: relative;
          left: -88px;
          top: -6px;
          padding-bottom: 9px;
          height: auto;
          padding-right: 5px;

          li {
            display: contents;
          }
        }
      }
    }

    ul {
      margin-left: 0;
      position: relative;
      top: 66px;

      li {
        list-style: none;
        float: left;
        margin: 0;
        padding: 0;

        a {
          display: block;
          white-space: nowrap;
          @media only screen and (max-width: $HEADER_RESPONSIVENESS_THRESHOLD) {
            padding: 12px 16px;
          }
          padding: 5px 16px;
          margin-left: 0;
          color: #fefefe;
          text-transform: uppercase;
          font-size: 0.875rem;
          text-decoration: none;
        }

        a.active {
          border-radius: 5px 5px 0 0;
          color: #fefefe;
          background: $COLOR_LINK_ELIXIR;
        }

        a.active:hover {
          color: #222;
          background: #d46d11;
          transition: color 0.25s;
        }

        a:hover {
          color: $COLOR_LINK_ELIXIR;
          border-color: $COLOR_LINK_ELIXIR;
          text-decoration: none;
          transition: color 0.25s, background 0.25s;
        }
      }
    }
  }
}
