.cards {
  display: flex;
  flex-wrap: wrap;

  .card-id.smaller {
    height: 175px;
  }


  .card-id {
    &.card-id-sab {
      width: 220px;
      border: 0;
    }

    width: 190px;
    height: 350px;
    border: solid 2px #e6e6e6;
    border-radius: 7px 7px 0px 0px;
    padding: 8px;
    margin: 10px;

    .card-id-smallphoto img {
      width: auto;
      padding-left: 4px;
      padding-bottom: 3px;
    }

    .card-id-photo img {
      width: 120px;
      padding-left: 4px;
      padding-bottom: 8px;
      margin: 0px auto;
      display: block;
    }

    .card-id-name {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }

    .card-id-description {
      font-size: 14px;
      text-align: center;
    }
  }
}
