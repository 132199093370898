#site-footer-primary {
  line-height: 1.5;
  
  #site-footer-primary-wrapper {
    background: #3e3e3e;
    font-size: 0.875rem;

    div {
      margin-left: 15px;
      margin-right: 15px;
      min-width: 15%;

      .site-footer-primary-logo {
        padding-top: 10px;
        padding-bottom: 5px;
      }

      h3 {
        border-bottom: 1px solid #848484;
        font-size: 1.15rem;
        color: #ebebeb;
        transition: color 0.15s;
        margin-bottom: 5px;

        a {
          color: #ebebeb;
          text-decoration: none;
        }

        a:hover {
          text-decoration: none;
          color: $COLOR_LINK_ELIXIR;
        }
      }

      ul {
        padding-inline-start: 0;
        margin-block-start: 0;

        li {
          list-style: none;
          width: auto;
          float: none;
          min-width: 0;
          color: #ebebeb;

          @media only screen and (max-width: $FOOTER_RESPONSIVENESS_THRESHOLD) {
              padding-bottom: 9px;
          }

          a {
            display: inline-block;
            zoom: 1;
            font-size: 14px;
            line-height: 1.1;
            color: #ebebeb;
            padding: 1px 4px 2px 0;
            border-bottom: none;
            margin-bottom: 0;
            text-decoration: none;
          }

          a:hover {
            text-decoration: none;
            transition: color 0.25s;
            color: $COLOR_LINK_ELIXIR;
          }
        }
      }
    }
  }
}

#site-footer-secondary {
  #site-footer-secondary-wrapper {
    background: #023452;
    border-top: 1px solid #333;
    color: #ebebeb;
    font-size: 0.875rem;
    flex-wrap: wrap-reverse;

    div {
      margin-left: 15px;
    }

    ul {
      list-style: none;
      padding-inline-start: 0;
      padding-right: 100px;
      @media only screen and (min-width: $FOOTER_RESPONSIVENESS_THRESHOLD) {
        li {
          display: inline-block;
        }
      }
      @media only screen and (max-width: $FOOTER_RESPONSIVENESS_THRESHOLD) {
        li {
          display: list-item;
          margin-top: 7px;
        }
      }

      a {
        display: inline-block;
        text-decoration: none;
        color: #ffffff;
        font-weight: 800;
        padding-left: 15px;
        padding-right: 15px;
      }

      a:hover {
        transition: color 0.25s;
        color: orange;
        text-decoration: none;
      }
    }

    #address {
      color: #999;
      font-size: 14px;
      padding-top: 14px;
      @media only screen and (max-width: $FOOTER_RESPONSIVENESS_THRESHOLD) {
        width: 90%;
      }
    }
  }
}
