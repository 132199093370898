.pull-right {
  float: right;
  color: orange;
  font-weight: 700;
  font-size: larger;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

h1.no-caps {
  text-transform: unset;
}

.no-underline {
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.justify {
  text-align:justify;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.centercolumns {
  justify-content: center; 
  column-gap: 15px;
}
