// RESPONSIVENESS OF LAYOUT

$MOBILE_DEVICES: 600px;
$TABLET_DEVICES: 1200px;
$LOW_RES: 1605px;
$HD_RES: 1930px;

@media only screen and (max-width: $MOBILE_DEVICES) {
  .index-flex {
    display: block;
  }
  #right-column {
    padding-left: 5px;
    .post {
      width: calc(100% - 20px);
      border-right: solid 1px #eee;
      border-top: solid 1px #eee;
      border-radius: 20px 20px 0px 0px;
      padding-top: 10px;
    }
    h3 {
      text-align: center;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: $LOW_RES) {
  .body-wrapper {
    padding-left: 2%;
  }

  .hide-in-lowres {
    display: none;
  }
}

@media only screen and (min-width: $MOBILE_DEVICES) {
  .index-flex {
    display: flex;
  }
  #site-content article div.narrower-article-div .index-flex .index-gray-box {
    min-width: 300px;
    width: 400px;
  }
}

@media only screen and (max-width: $TABLET_DEVICES) {
  .margin-wrapper {
    padding-left: 2%;
  }

  #site-header #site-header-wrapper .elixir-image {
    left: 20px;
  }

  #site-content {
    article {
      display: block;
      div#left-column {
        padding-top: 0px;
        width: calc(98% - 40px);
        ul {
          width: calc(96% - 40px);
        }
      }
      div.narrower-article-div .index-flex .index-gray-box {
        padding: 5px;
        p {
          margin-block-start: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: $TABLET_DEVICES) and (max-width: $HD_RES) {
  .margin-wrapper {
    padding-left: calc(2% + 200px);
  }
  .body-wrapper.margin-wrapper {
    padding-left: 2%;
  }
  div#right-column {
    width: calc(65% - 240px);
  }
  div#right-column.right-column-wider {
    width: calc(87% - 240px);
  }
}

@media only screen and (min-width: $HD_RES) {
  .margin-wrapper {
    padding-left: 20%;
  }
  .body-wrapper {
    padding-left: calc(20% - 220px);
    width: calc(85% - 200px);
  }
  div#right-column {
    width: calc(60% - 240px);
  }
  div#right-column.right-column-wider {
    width: calc(80% - 240px);
  }
}
