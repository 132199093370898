.event-info {
    min-width: 175px;
    min-height: 60px;
    float: right;
    border: solid 2px rgb(2, 52, 82);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 60px;
    margin-left: 50px;
    text-align: center;

    span {
        display: block;
        padding-bottom: 2px;
    }

    .event-info-date {
        font-size: 15pt;
    }

    .event-info-duration {
        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }

        font-size: smaller;
        text-align: center;
    }

    .event-info-no-participants {
        text-align: center;
    }
    
    .event-info-register-link {
        a {
            font-size: x-large;
            color: $COLOR_LINK_ELIXIR;
            text-align: center;
        }
    }

    .event-info-image {
        max-width: 220px;
        margin-top: 40px;
        border: solid 1px #f5f5f5;
    }
}

.event-small-image {
    max-width: 100px;
    border: solid 1px #f5f5f5;
}