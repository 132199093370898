.accordion {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);

  .accordion-item {
    width: 100%;
    color: #f3efef;
    overflow: hidden;

    label {
      display: flex;
      padding: 1em;
      background: #2c3e50;
      justify-content: space-between;
      font-weight: bold;
      cursor: pointer;
      border-bottom: solid 1px white;

      &:hover {
        background: #1a252f;
      }

      &:after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked + label {
        background: #1a252f;
      }

      &:checked + label:after {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
      }

      &:checked ~ .accordion-content {
        max-height: 100vh;
        padding: 1em;
      }
    }

    .accordion-content {
      max-height: 0;
      padding: 0 1em;
      color: #2c3e50;
      background: white;
      transition: all .35s;
      font-size: 16px;
    }
  }
}
